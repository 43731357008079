@import 'src/styles/variables';

.root {
  background: #808dc8;
  background: linear-gradient(233deg, #808dc8 22%, #b6a8e4 50%, #a273da 94%);
  padding: 39px 0 20px 0;
}

.algolia {
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  flex-wrap: wrap;
  margin: auto;
}

.containerLinks {
  display: contents;
}

.logoColumnContainer {
  margin-top: $spacing * 3.5;
  width: 265px;
  padding-top: 0px !important;
}

.logoText {
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 10px;
  font-weight: 500;
}

.logo {
  height: 35px;
  margin: 0 auto;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding-top: 39px;
}

.title {
  margin-bottom: $spacing * 0.7;
  font-weight: 600;
}

.list>li {
  margin: 8px 0 8px 8px;
  font-weight: 500;
}

.link {
  display: flex;
  align-items: center;
}

.arrowIcon {
  height: 16px;
  width: auto;
  fill: currentColor;
  margin-right: $spacing / 2;
  transform: scaleX(-1);
  opacity: .25;
}

.socialNetworkIconContainer {
  margin: auto;
}

.socialNetworkLink {
  margin-left: $spacing *1.8;
}

.socialNetworkIcon {
  height: 20px;
  color: currentColor;
  fill: currentColor;
}

.linkTypography {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: white;
}

.email {
  font-weight: 600;
}


.searchByAlgolia {
  height: 20px;
  width: auto;
  margin-top: $spacing * 2;
}

.languageSelectorListItem {
  margin-top: $spacing * 3 !important;
}

@media screen and (max-width: 850px) {}

@media screen and (max-width: 480px) {
  .content {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .logoColumnContainer {
    margin-top: $spacing
  }

  .containerLinks {
    margin: auto;
    display: unset;
  }

  .list {
    margin: auto;
  }

  .linkTypography {
    justify-content: center;
  }

  .list>li {
    margin: 8px 0 8px 0;
    font-weight: 500;
  }
}