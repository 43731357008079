@import 'src/styles/variables';

.root {
    width: fit-content;
    max-width: 100%;
    padding: $spacing * 0.88 $spacing * 3.5;
    cursor: pointer;
    position: relative;
    border: none;
    background-color: unset;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    overflow: hidden;
    min-height: 36px;
}

.iconButton {
    padding: $spacing * 1.5;
}

.disabled {
    cursor: not-allowed;
}

.variant-outlined {
    border: 1px solid currentColor;
}

.brightLayer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    z-index: 1;
}

.typography {
    display: flex;
    align-items: center;
    text-align: center;
    color: currentColor !important;
}
