@import 'src/styles/variables';

.root {
  font-weight: 500;
  font-size: 30px;
  padding: $spacing * 3 $spacing * 5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
