@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;500;600;700;800;900&family=Lato:wght@400;600;700;900&display=swap');

html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

body {
    font-family: 'Assistant', sans-serif;
    font-weight: 400;
    scroll-behavior: smooth;
    overflow-x: hidden;
    //animation: fade-in 1s ease;
}

#root {
    display: flex;
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: inherit;
}

*:focus {
    outline: none;
}

textarea {
    resize: none;
}

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

ul {
    padding: 0;
}

.customScrollBar::-webkit-scrollbar-track {
    border: 0
}

.customScrollBar {
    &::-webkit-scrollbar-track {
        border: 0
    }

    &::-webkit-scrollbar {
        height: 6px;
        width: 6px
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.4)
    }
}

.ais-Hits-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}