.root {
  flex: 1;
}

.withPersistentDrawerOpenedRoot {
  overflow: hidden;
}

.content {
  min-height: calc(100vh - 60px);
}

.toaster {
  box-shadow: 0 5px 20px 10px rgba(0, 0, 0, 0.05) !important;
  padding: 8px * 2 8px * 3 !important;
  > div:not(:last-child) {
    margin-right: 8px !important;
  }
}
