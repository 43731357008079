@import 'src/styles/variables';

.root {
  display: flex;
  align-items: center;
}

.language {
  margin: 0 4px;
  text-transform: uppercase;
}

.language:not(.currentLanguage) {
  color: rgba(0, 0, 0, 0.6)
}

.currentLanguage {
  font-weight: 600;
}

.divider {
  height: 26px;
  width: 1px;
  margin: 0 $spacing
}


@media screen and (max-width: 480px) {
  .root {
    justify-content: center;
  }
}