@import 'src/styles/variables';

.root {
  padding: $spacing * 5
}

@media screen and (max-width: 400px) {
  .root {
    padding: $spacing * 2
  }
}
