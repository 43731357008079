@import 'src/styles/variables';

.root {
  display: flex;
  flex-direction: column;
}

.globalError {
  text-align: center;
  margin-bottom: $spacing * 3;
}

.formField {
  margin-bottom: $spacing * 2;
}

.icon {
  height: 22px;
  width: auto;
  display: flex;
  fill: currentColor;
  opacity: .5;
}

.forgotPassword {
  align-self: flex-start;
}

.submit {
  align-self: flex-end;
}
