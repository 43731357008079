@import 'src/styles/variables';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin: $spacing * 6 0 $spacing * 2.5;
}

.headerText {
  display: flex;
  align-items: center;
}

.icon {
  height: 26px;
  width: auto;
  margin-right: $spacing * 2;
}
