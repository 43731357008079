.root {
    line-height: 1.4;
}

.h1 {
    font-family: 'Lato', sans-serif;
    font-size: 80px;
    font-weight: 700;
    letter-spacing: -1.5px;
}

.h2 {
    font-family: 'Lato', sans-serif;
    font-size: 52px;
    font-weight: 600;
}

.h3 {
    font-family: 'Lato', sans-serif;
    font-size: 35px;
    font-weight: 500;
}

.h4 {
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 500;
}

.h5 {
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: 500;
}

.h6 {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 500;
}

.subtitle1 {
    font-family: 'Assistant', sans-serif;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.15px;
}

.subtitle2 {
    font-family: 'Assistant', sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.1px;
}

.body1 {
    font-family: 'Assistant', sans-serif;
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.body2 {
    font-family: 'Assistant', sans-serif;
    font-size: 15px;
    line-height: 23px;
    font-weight: 400;
    letter-spacing: 0.25px;
}

.button {
    font-family: 'Assistant', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.caption {
    font-family: 'Assistant', sans-serif;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.4px;
}

.overline {
    font-family: 'Assistant', sans-serif;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

@media screen and (max-width: 850px) {
    .h1 {
        font-size: 60px;
    }

    .h2 {
        font-size: 46px;
    }

    .h3 {
        font-size: 30px;
    }

    .h4 {
        font-size: 26px;
    }

    .h5 {
        font-size: 22px;
    }

    .h6 {
        font-size: 19px;
    }
}

@media screen and (max-width: 550px) {
    .h1 {
        font-size: 52px;
    }

    .h2 {
        font-size: 38px;
    }

    .h3 {
        font-size: 24px;
    }

    .h4 {
        font-size: 22px;
    }

    .h5 {
        font-size: 20px;
    }

    .h6 {
        font-size: 18px;
    }
}

@media screen and (max-width: 375px) {
    .h1 {
        font-size: 44px;
    }

    .h2 {
        font-size: 30px;
    }

    .h3 {
        font-size: 20px;
    }

    .h4 {
        font-size: 19px;
    }

    .h5 {
        font-size: 18px;
    }

    .h6 {
        font-size: 17px;
    }
}


