.root {
  height: 100vh;
  width: 325px;
  max-width: 100vw;
  border-radius: 0;
  padding: 0;
  z-index: 100;
}

.temporary {
  position: fixed;
  top: 0;
}

.temporary.left {
  left: 0;
}

.temporary.right {
  right: 0;
}
