@import 'src/styles/variables';

.root {
  border-radius: 5px;
}

.icon {
  height: 22px;
  width: auto;
  margin-right: $spacing * 2;
  fill: currentColor;
}
