@import 'src/styles/variables';

.root {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    z-index: 1000001;
}

.wrapper {
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: $spacing * 2 $spacing;
}

.fullScreen .wrapper {
    padding: 0;
}

.backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.paper {
    min-height: 200px;
    min-width: 300px;
    max-width: calc(min(600px, 90vw));
    z-index: 1000002;
    border-radius: 5px;
    position: relative;
    padding: 0;
}

.fullScreen .paper {
    min-height: 100vh;
    width: 100% !important;
    max-width: none !important;
    border-radius: 0;
}
