@import 'src/styles/variables';

@import '../../../styles/global';

.root {
  padding: $spacing * 3 0 $spacing * 10;
  max-height: calc(100vh - 60px);
  overflow: auto;

  @extend .customScrollBar;

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4)
  }
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing * 3 $spacing * 2.5
}
