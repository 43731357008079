.root {
  height: 1px;
  min-height: 1px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  position: absolute;
  padding: 0 8px * 2;
  color: rgba(0, 0, 0, .6) !important;
  font-weight: 500;
  text-align: center;
}
