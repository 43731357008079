.root {
  z-index: 100;
  padding-bottom: 15px;
}

.paper {
  padding: 8px 8px * 2;
  border-radius: 5px;
  z-index: 1000002;
}
