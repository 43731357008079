.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.minFullScreenHeight {
  min-height: calc(100vh - 60px);
}
