@import 'src/styles/variables';

.root {
  display: flex;
  margin: -$spacing
}

.item {
  padding: $spacing * 2 $spacing * 4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $spacing;
  border-radius: 5px;
}

.icon {
  fill: currentColor;
  height: 24px;
  width: auto;
  margin-right: $spacing * 1.5;
}

.typography {
  color: currentColor !important;
}
