@import 'src/styles/variables';

.root {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.error {
  width: 100%;
  text-align: center;
  margin: 0 0 $spacing * 2;
}

.button {
  margin: $spacing / 2;
  min-width: 115px;
  flex: 1
}

.icon {
  fill: currentColor;
  height: 16px;
  width: auto;
  margin-right: $spacing;
}

.typography {}

@media screen and (max-width: 550px) {
  .button {
    min-width: unset;
    width: 40px;
    height: 40px;
  }

  .typography {
    display: none;
  }

  .icon {
    margin-right: 0;
  }
}

@media screen and (max-width: 400px) {
  .root {
    flex-direction: column;
  }

  .button {
    flex: unset;
    width: 100%;
    height: unset;
  }
  .icon {
    margin-right: $spacing;
  }

  .typography {
    display: block;
  }
}
