@import 'src/styles/variables';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  min-width: 42px;
  border-radius: 50%
}

.icon {
  height: 20px;
  width: 20px;
}
