@import 'src/styles/variables';

.paper {
  width: 95%;
  max-width: 500px
}

.typeToggle {
  margin-bottom: $spacing * 4;
}

.signInWithProvidersDivider {
  margin: $spacing * 5 0 $spacing * 3.5;
}
