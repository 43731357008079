@import 'src/styles/variables';

.menuIcon {
  height: 42px;
  width: auto;
  display: flex;
}

.mobileLinks{
  display: none;
}

@media screen and (max-width: 480px) {
  .mobileLinks{
    display: block;
  }
  
}