@import 'src/styles/variables';

.button {
  padding: 0 $spacing * 2 $spacing * 2;
  position: relative;
}

.underline {
  position: absolute;
top: 100%;  z-index: 1;
  left: 0;
  width: 100%;
  height: 1px;
}

.typography {
  font-weight: 700;
}
