@import 'src/styles/variables';

.root {
    width: fit-content;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 0;
    overflow: hidden;
}

.fullWidth {
    width: 100%
}

.disabled {
    cursor: not-allowed;
}

.input {
    background-color: transparent;
    width: 100%;
    min-height: 60px;
    padding: $spacing * 2 $spacing * 3;
    border: none;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Assistant', sans-serif;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-weight: 600;
    color: currentColor;
}

.withBeforeInput .input {
    padding-left: 0;
}

.withChildren .input {
    padding-right: 0;
}

.multiline .input::-webkit-scrollbar-track {
    border: 0
}

.multiline .input::-webkit-scrollbar {
    width: $spacing / 2
}

.multiline .input::-webkit-scrollbar-thumb {
    border-radius: 100px;
}

.childrenContainer {
    padding: $spacing $spacing * 2.5
}
