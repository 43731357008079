@import 'src/styles/variables';

.root {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 $spacing * 5;
    border-radius: 0;
    position: relative;
}

.transparent {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent !important;
    // border-bottom: 1px solid rgba(255, 255, 255, .3);
    z-index: 20;
    box-shadow: none !important;
}

.actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media screen and (max-width: 400px) {
    .root {
        padding: 0 $spacing * 2.5;
    }
}
