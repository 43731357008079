@import 'src/styles/variables';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  margin: 0 $spacing * 2 $spacing
}

.error {
  margin: $spacing $spacing * 2 0 $spacing * 2
}

.required {
  opacity: 0.3;
  margin-left: $spacing;
  line-height: 1;
}
