@import 'src/styles/variables';

.root {
  padding: $spacing $spacing * 2;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .9);
  z-index: 1000002;
}

.typography {
  line-height: 1.6;
}
