@import 'src/styles/variables';

.sponsorLogo {
    height: 41px;
    width: auto;
}

.sponsor {
    padding-left: 100px;
    color: #fff;
    font-weight: 500;
}

.sponsorName {
    position: relative;
    top: -18px;
    padding-left: 20px;
}

@media only screen and (max-width: 725px) {
    .sponsorName {
        display: none;
    }
}
